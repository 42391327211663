import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { UserProfile } from '../../models/user-profile';
import { AppUrlsConfig } from '../app-urls.config';
import { UserProfileDto } from '../mappers/dto/user-profile.dto';
import { UserProfileMapper } from '../mappers/user-profile.mapper';

/** Service to get info about current user. */
@Injectable({
  providedIn: 'root',
})
export class CurrentUserApiService {
  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly httpClient = inject(HttpClient);

  private readonly userMapper = inject(UserProfileMapper);

  /** Returns current user info.*/
  public getCurrentUser(): Observable<UserProfile | null> {
    return this.httpClient
      .get<UserProfileDto>(this.apiUrls.userAuth.currentProfile)
      .pipe(
        map(user => this.userMapper.fromDto(user)),
        catchError(() => of(null)),
      );
  }
}
